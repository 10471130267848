import React, { useEffect, useState } from "react";
import { RichText } from "prismic-reactjs";
import clsx from "clsx";
// import styled from "styled-components";

// const ColValue = styled.div`
//   position: absolute;
//   left: 29.842342342%;
// `;
// const Item = styled.div`
//   // position: absolute;
//   display: ${(props) => (props.active ? "block" : "none")};
//   // min-height: 150px;
// `;

const TitleTexte = ({ input, activeIndex, index }) => {
  // const [active, setActive] = useState(activeIndex === index);
  // const [show, setShow] = useState();

  // useEffect(() => {
  //   const tokenA = PubSub.subscribe("ABOUT_ITEM", (e, d) => {
  //     setActive(d === index);
  //   });
  //   const tokenB = PubSub.subscribe("ABOUT_ITEM_HOVER", (e, d) => {
  //     setShow(d);
  //   });

  //   return () => {
  //     PubSub.unsubscribe(tokenA);
  //     PubSub.unsubscribe(tokenB);
  //   };
  // }, []);

  // const _onClick = () => {
  //   PubSub.publish("ABOUT_ITEM", index);
  // };
  return (
    <div
      className={clsx(
        "item title-texte",
        activeIndex === index ? "is-active" : ""
      )}>
      <div className='flex w-full'>
        <div className='col-value pr-sm'>
          <div>
            <RichText render={input.primary.texte.raw} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitleTexte;
