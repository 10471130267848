import React, { useState } from "react";
import { Link } from "gatsby";
import clsx from "clsx";
import { _linkResolver } from "../../core/utils";

const Liste = ({ input, activeIndex, index }) => {
  // const [active, setActive] = useState(activeIndex === index);
  // console.log(activeIndex, index, active);
  return (
    <div
      className={clsx(
        "item liste col-value w-full ",
        activeIndex === index ? "is-active" : ""
      )}>
      <ul>
        {input.items.map((li, i) => (
          <li key={i} className='flex'>
            <div className='editor pr-sm '>{li.editor}</div>
            <div className='year pr-sm'>{li.year}</div>
            <div className='title md:pr-sm italic'>{li.title}</div>
            <div className='link '>
              {li.link_label && (
                <div className='inner'>
                  {li.link.target === "_blank" && (
                    <a
                      href={li.link.url}
                      target='_blank'
                      rel='noopener, noreferrer'>
                      {li.link_label}
                    </a>
                  )}
                  {li.link.document && li.link.document.uid && (
                    <Link
                      to={`${_linkResolver(li.link.document)}`}
                      className='text-secondary italic whitespace-nowrap'>
                      {li.link_label}
                    </Link>
                  )}

                  {li.link.target !== "_blank" && !li.link.document && (
                    <div className='text-primary sans whitespace-nowrap'>
                      {li.link_label}
                    </div>
                  )}
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Liste;
