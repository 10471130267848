import React, { useState } from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";
import SEO from "../components/seo";
import TitleTexte from "../components/slices/TitleTexte";
import Liste from "../components/slices/Liste";

import Clock from "../components/ui/Clock";
import clsx from "clsx";

export const pageQuery = graphql`
  query ContactQ {
    prismicAbout {
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_image {
          url
        }
        body {
          ... on PrismicAboutBodyTitleTexte {
            slice_type
            primary {
              title {
                text
              }
              marge
              texte {
                raw
              }
            }
          }
          ... on PrismicAboutBodyListe {
            id
            slice_type
            primary {
              title {
                text
              }
              marge
            }
            items {
              editor
              year
              title
              link_label
              link {
                target
                url
                document {
                  ... on PrismicProject {
                    uid
                    type
                    data {
                      categories {
                        category_principale
                        category {
                          document {
                            ... on PrismicCategory {
                              uid
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const About = ({ data, pageContext }) => {
  const { template } = pageContext;
  const { meta_title, meta_description, meta_image, body } =
    data.prismicAbout.data;

  const [index, setIndex] = useState(0);

  // useEffect(() => {}, [])

  const slices = body.map((slice, i) => {
    // console.log(index);
    switch (slice.slice_type) {
      case "title_texte":
        return (
          <TitleTexte key={i} input={slice} activeIndex={index} index={i} />
        );
      case "liste":
        return <Liste key={i} input={slice} activeIndex={index} index={i} />;

      default:
        return null;
    }
  });

  return (
    <div className='page-template page-about bg-gray--'>
      <SEO
        pageTitle={meta_title.text}
        pageDescription={meta_description.text}
        pageImage={meta_image.url}
        template={`${template} `}
        page={true}
        documentClass='bg-gray'
      />

      <div className='px-sm md:px-md'>
        <div
          className='row custom-gutter'
          style={{
            "--custom-gutter": "17px",
          }}>
          <div className='col-auto col-site-name hidden-sm'></div>
          <div className='col-auto col-h1 hidden-sm'></div>
          <div className='col-auto col-menu'>
            {/* <div>index: {index}</div> */}
            <div className='flex'>
              <div className={clsx("col-keys flex flex-col", `is-${index}`)}>
                {body.map((slice, i) => (
                  <div
                    className={clsx(
                      "col-key cursor-pointer",
                      index === i ? "is-active" : "",
                      `item-${slice.primary.title.text.toLowerCase()}`
                    )}
                    style={{
                      paddingBottom: slice.primary.marge
                        ? slice.primary.marge
                        : 0,
                    }}
                    key={i}
                    onClick={() => setIndex(i)}>
                    {slice.primary.title.text}
                  </div>
                ))}
              </div>

              <div className='col-values'>{slices}</div>
            </div>
          </div>
        </div>
        <Clock />
      </div>
    </div>
  );
};

export default withPreview(About);
